<template>
	<div>
		<div class="container">
			<div>
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="电话号码" class="mr10"></el-input>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10" @click="numberPoolGmRecordss">查询</el-button>
			</div>
			<el-table
				v-loading="loading"
				:data="tableData"
				border
				class="table m_t_20"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="agentName" label="代理商" align="center"></el-table-column>
				<el-table-column prop="companyName" label="企业" align="center"></el-table-column>
				<el-table-column prop="departmentName" label="部门" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="号码" align="center"></el-table-column>
				<el-table-column prop="time" label="时间" align="center"></el-table-column>
				<!-- 	<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-switch
							v-if="scope.row.isStop == false"
							v-model="scope.row.isStop"
							active-color="#13ce66"
							inactive-color="#ff4949"
							@change="getswitch(scope.row)"
						></el-switch>
						<el-switch v-else disabled v-model="scope.row.isStop" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
					</template>
				</el-table-column> -->
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[30, 50, 100, 500]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { getGMAbnormalNumber, GMStop } from '../api/reportNumber.js';
export default {
	name: 'validate',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				phoneNumber: '',
				pageIndex: 1,
				pageSize: 30
			},
			pageTotal: 0,
			tableData: []
		};
	},
	created() {
		this.getGMAbnormalNumber();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},

		getGMAbnormalNumber() {
			this.query.phoneNumber = this.query.phoneNumber.replaceAll(' ', '');
			getGMAbnormalNumber(this.query).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getGMAbnormalNumber();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getGMAbnormalNumber();
		},
		getswitch(row) {
			let data = {
				phoneNumber: row.phoneNumber
			};
			GMStop(data).then(res => {
				if (res.code == 200) {
					this.$message.success('操作成功');
					this.getGMAbnormalNumber();
				} else {
					this.$message.error(res.message);
				}
			});
		}
	}
};
</script>

<style scoped>
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 12px;
}

.mr10 {
	width: 200px;
	margin-right: 10px;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
/deep/ .el-col-16 {
	flex: 0 0 59.9%;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-num {
	font-size: 30px;
	font-weight: bold;
}

.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-con-1 .grid-con-icon {
	background: linear-gradient(#ffb199, #ff0844);
}
.new {
	color: #b5b4b4;
	font-size: 12px;
}
.grid-con-1 .grid-num {
	color: #4f7afd;
}

.grid-con-2 .grid-con-icon {
	background: linear-gradient(#48c6ef, #6f86d6);
}

.grid-con-2 .grid-num {
	color: #4f7afd;
}

.grid-con-3 .grid-con-icon {
	background: linear-gradient(#e5b2ca, #7028e4);
}

.grid-con-3 .grid-num {
	color: #4f7afd;
}
.grid-con-4 .grid-con-icon {
	background: linear-gradient(#00c6fb, #005bea);
}

.grid-con-4 .grid-num {
	color: #4f7afd;
}
.grid-con-5 .grid-con-icon {
	background: linear-gradient(#89f7fe, #66a6ff);
}

.grid-con-5 .grid-num {
	color: #4f7afd;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;

	display: inline-block;
}
</style>
<style>
.validate .el-upload {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}
.validate .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
